<template>
  <section>
    <sub-head idName="mana-evo" content="管理沿革"></sub-head>
    <section class="box">
      <DisplayCard
        v-for="({ imgName, teacherName, content }, index) in TeaIntroData.data"
        :key="imgName"
        :header="TeaIntroData.title"
        :index="index"
      >
        <tea-intro
          :imgName="imgName"
          :teacherName="teacherName"
          :type="index % 2 === 0 ? 'odd' : 'even'"
        >
          <p v-for="item in content" :key="item">{{ item }}</p>
        </tea-intro>
      </DisplayCard>
    </section>
    <section class="box">
      <DisplayCard
        v-for="(items, index) in StuIntroData.data"
        :key="index"
        :header="StuIntroData.title"
        :index="index"
      >
        <stu-intro :data="items" :year="index + 1"></stu-intro>
      </DisplayCard>
    </section>
  </section>
</template>
<script>
import SubHead from '../../../components/SubHead.vue'
import StuIntro from './ManaEvo/StuIntro.vue'
import TeaIntro from './ManaEvo/TeaIntro.vue'
import DisplayCard from '@/components/DisplayCard.vue'
import stuintroData from '@/assets/json/stuIntro.json'
import teacherIntroData from '@/assets/json/teacherIntro.json'

export default {
  components: { SubHead, TeaIntro, DisplayCard, StuIntro },
  name: 'ManaEvo',
  mounted() {},
  data() {
    return {
      StuIntroData: {
        title: '主席团',
        data: stuintroData
      },
      TeaIntroData: {
        title: '指导老师',
        data: teacherIntroData
      }
    }
  }
}
</script>
<style scoped>
.box-subhead {
  /* display: inline-block; */
  width: 6rem;
  text-align: end;
  background-color: #ffffff;
  color: #5fbcff;
  margin: 1rem 0rem 0.5rem -0.5rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
  font-size: 1.1rem;
}
</style>
