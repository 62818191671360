<template>
  <section>
    <sub-head idName="dev-his" content="发展历程" />
    <section class="time-line-box">
      <div class="dot"></div>
      <div class="time-line">
        <time-line
          :time="data.time"
          :type="data.type"
          v-for="data in timeLineData"
          :key="data.time"
        >
          {{ data.content }}
        </time-line>
      </div>
    </section>
  </section>
</template>
<script>
import SubHead from '../../../components/SubHead.vue'
import TimeLine from '../../../components/TimeLine.vue'
export default {
  components: { SubHead, TimeLine },
  name: 'DevHis',
  data() {
    return {
      timeLineData: [
        {
          time: '2013/5',
          type: 'large',
          content:
            '杭电助手上线试行，推出查课表、阳光长跑达标查询、查天气、空教室及时查询等功能，迈出了长跑的第一步。'
        },
        {
          time: '2013/7',
          type: 'small',
          content: '杭电助手成绩查询功能开通，进一步成为杭电人的学习小助手。'
        },
        {
          time: '2013/9',
          type: 'small',
          content:
            '杭电助手正式成立社团，招贤纳士，招集在排版、文案、设计、技术开发和维护等方面有能力的同学，致力于微信公众号的开发运营与维护，为学校师生提供科技服务，打造杭电人的移动服务平台。'
        },
        {
          time: '2014/5',
          type: 'large',
          content: '杭电助手关注量突破两万。'
        },
        {
          time: '2015/8',
          type: 'large',
          content: '招新系统正式上线，陆续完善杭电助手各项功能。'
        },
        {
          time: '2016/8',
          type: 'large',
          content: '杭电助手入选腾讯微校标杆项目。'
        },
        {
          time: '2017',
          type: 'large',
          content:
            '杭电助手位列全国高校微校公众号第一名，其开发的几个大型功能如自动报道系统也受到在校学生一致好评。'
        },
        {
          time: '2020/10',
          type: 'large',
          content:
            '杭电助手登上《中国教育报》头条，《中国教育报》以《构建智慧学院赋能人才培养》为题，用2800字的篇幅报道“杭电助手”开发团队。'
        }
      ]
    }
  }
}
</script>
